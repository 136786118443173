import React, { createContext } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { IsClient } from '../profiles/isClient'

export const Bitrix24WidgetContext = createContext({})

function Bitrix24WidgetProvider({ children }) {
  const [isClient, setIsClient] = React.useState(false)
  const state = useSelector((state) => state)
  const company = useSelector((state) => state.me?.person?.company)

  const injectScript = () => {
    const script = document.createElement('script')
    script.async = true
    script.src =
      'https://cdn.bitrix24.com.br/b31691961/crm/site_button/loader_2_vgx61q.js?' +
      ((Date.now() / 60000) | 0)
    const firstScript = document.getElementsByTagName('script')[0]
    firstScript.parentNode.insertBefore(script, firstScript)
  }

  React.useEffect(() => {
    if (company?.id_company === 2 && isClient === true) {
      injectScript()
    }
  }, [company, isClient])

  React.useEffect(() => {
    setIsClient(IsClient(state))
  }, [state.me?.profiles])

  return <Bitrix24WidgetContext.Provider value={{}}>{children}</Bitrix24WidgetContext.Provider>
}

Bitrix24WidgetProvider.propTypes = {
  children: PropTypes.node,
}

export { Bitrix24WidgetProvider }

export default Bitrix24WidgetProvider
