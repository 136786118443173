import HttpService from '../http/httpService'

class UserService extends HttpService {
  resource = '/user'
  primaryKey = 'id_user'

  async documentExists(document) {
    return this.instance.get(`${this.resource}/exists?document=${document}`)
  }

  async emailExists(email) {
    return this.instance.get(`${this.resource}/exists?email=${email}`)
  }

  async searchRepresentative(filters) {
    return this.instance.get(
      `${this.resource}/representative/search?${this._serializeFilter(filters)}`,
    )
  }

  async searchFrenteVendas(filters) {
    return this.instance.get(
      `${this.resource}/frente-vendas/search?${this._serializeFilter(filters)}`,
    )
  }

  async loadUsersSelect(value, idCompany = null, idUserCompany = null, idEstablishment = null) {
    let filter = { 'person.name': value }
    if (idCompany) {
      filter = { ...filter, ...{ 'person.id_company': idCompany } }
    }

    if (idUserCompany) {
      filter.id_user = idUserCompany
    }

    if (idEstablishment) {
      filter = { ...filter, ...{ 'establishments.id_establishment': idEstablishment } }
    }
    const response = await this.search(filter)
    const result = response.data.map(function (item) {
      return {
        label: item.person.name,
        value: item.id_user,
        document: item.person.document,
        email: item.email,
      }
    })
    return result.sort((a, b) => a.label.localeCompare(b.label))
  }

  async loadSearchClient(filters) {
    let myFilter = {}

    if (filters.name && !filters.id_company && !filters.id_establishment) {
      myFilter = { ...myFilter, ...{ 'person.name': filters.name } }
    }

    if (filters.id_company && !filters.id_establishment) {
      myFilter = { ...myFilter, ...{ 'person.id_company': filters.id_company } }
    }

    if (filters.id_establishment && !filters.id_company) {
      myFilter = { ...myFilter, ...{ 'establishments.id_establishment': filters.id_establishment } }
    }

    if (filters.id_establishment && filters.id_company) {
      myFilter = { ...myFilter, ...{ 'establishments.id_establishment': filters.id_establishment } }
      myFilter = { ...myFilter, ...{ 'person.id_company': filters.id_company } }
    }

    if (filters.id_user_representative) {
      myFilter = {
        ...myFilter,
        ...{ 'person.id_user_representative': filters.id_user_representative },
      }
    }

    if (filters.id_user) {
      myFilter = { ...myFilter, ...{ 'person.id_user': filters.id_user } }
    }

    myFilter = { ...myFilter, ...filters }

    const response = await this.search(myFilter)
    return response.data.map(function (item) {
      return {
        label: item.person.name,
        value: item.id_user,
        document: item.person?.document,
        email: item.email,
      }
    })
  }

  async loadUsersRepresentativeSelect(value) {
    const response = await this.searchRepresentative({ 'person.name': value })
    return response.data.map(function (item) {
      return {
        label: item.person.name,
        value: item.id_user,
        document: item.person.document,
        email: item.email,
      }
    })
  }

  async loadUsersFrenteVendasSelect(value) {
    const response = await this.searchFrenteVendas({ 'person.name': value })
    return response.data.map(function (item) {
      return {
        label: item.person.name,
        value: item.id_user,
        document: item.person.document,
        email: item.email,
      }
    })
  }

  recoveryPassword(data) {
    return this.instance.post(`${this.resource}/recovery_password`, data)
  }

  sendNewPassword(data) {
    return this.instance.post(`${this.resource}/new_password`, data)
  }

  me() {
    return this.instance.get(`${this.resource}/me`)
  }

  setStatus(id_user, id_user_status, block_reason = null) {
    return this.instance.post(`${this.resource}/${id_user}/status`, {
      id_user_status,
      block_reason: block_reason,
    })
  }

  async unarchive(id_user) {
    return this.instance.patch(`${this.resource}/${id_user}/unarchive`)
  }

  changeEmailPlaceholder(userId, newEmail) {
    return this.instance.post('/person/changes-requests', {
      id_user_changes: userId,
      data: newEmail,
    })
  }

  async getDevices(id_user) {
    return this.instance.get(`${this.resource}/${id_user}/device`)
  }

  async approveDevice(id_user, device, authorized_by) {
    return this.instance.post(`${this.resource}/${id_user}/device/approve`, {
      authorized_by: authorized_by,
      device: device,
    })
  }

  async inactivateDevice(id_user, device, authorized_by) {
    return this.instance.post(`${this.resource}/${id_user}/device/deny`, {
      authorized_by: authorized_by,
      device: device,
    })
  }

  async loadSharedAccess(id_user) {
    return this.instance.get(`${this.resource}/${id_user}/shared-access`)
  }

  async storeSharedAccess(id_user_owner, id_user_granted, id_shared_access_type) {
    return this.instance.post(`${this.resource}/${id_user_owner}/shared-access`, {
      id_user_granted: id_user_granted,
      id_shared_access_type: id_shared_access_type,
    })
  }

  async removeSharedAccess(id_user_owner, id_user_granted) {
    return this.instance.delete(
      `${this.resource}/${id_user_owner}/shared-access/${id_user_granted}`,
    )
  }

  async attachCelcoin(id_user) {
    return this.instance.post(`${this.resource}/${id_user}/register/celcoin`)
  }

  async resendKycCelcoin(baas_celcoin_id) {
    return this.instance.post(`celcoin/${baas_celcoin_id}/resend-kyc`)
  }

  async accountCloseCelcoin(baas_celcoin_id) {
    return this.instance.post(`celcoin/${baas_celcoin_id}/account-close`)
  }

  async resetOnboard(id_user) {
    return this.instance.post(`${this.resource}/${id_user}/reset_onboard`)
  }

  async resetPin(id_user) {
    return this.instance.post(`${this.resource}/${id_user}/reset_pin`)
  }

  async runBigData(id_user) {
    return this.instance.post(`${this.resource}/${id_user}/run_bigdata`)
  }

  async getRepresentanteAcquirerRate(id_user) {
    return this.instance.get(`${this.resource}/representative/${id_user}/acquirer-rate`)
  }

  async storeRepresentanteAcquirerRate(id_user, data) {
    return this.instance.post(`${this.resource}/representative/${id_user}/acquirer-rate`, data)
  }

  async getRepresentanteBaasRate(id_user) {
    return this.instance.get(`${this.resource}/representative/${id_user}/baas-rate`)
  }

  async storeRepresentanteBaasRate(id_user, data) {
    return this.instance.post(`${this.resource}/representative/${id_user}/baas-rate`, data)
  }

  async sendTableFile(file) {
    return this.instance.post(`${this.resource}/representative/import-rate`, file)
  }
}

export default new UserService()
