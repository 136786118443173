import { trans } from '../components/translate/translate'

export default class ProfileEnum {
  static MASTER = 6
  static ADMIN = 1
  static SUPERVISOR = 2
  static SUPPORT = 3
  static FRENTE_VENDA = 9

  static MANAGER = 7
  static REPRESENTATIVE = 4
  static ASSISTANT = 8
  static CLIENT = 5

  static MasterProfiles = () => {
    return [
      {
        id: 1,
        label: trans('MASTER'),
        value: ProfileEnum.MASTER,
      },
      {
        id: 2,
        label: trans('ADMIN'),
        value: ProfileEnum.ADMIN,
      },
      {
        id: 3,
        label: trans('SUPERVISOR'),
        value: ProfileEnum.SUPERVISOR,
      },
      {
        id: 4,
        label: trans('SUPPORT'),
        value: ProfileEnum.SUPPORT,
      },
      {
        id: 5,
        label: trans('FRENTE_VENDA'),
        value: ProfileEnum.FRENTE_VENDA,
      },
    ]
  }

  static WhiteLabelProfiles = () => {
    return [
      {
        id: 1,
        label: trans('MANAGER'),
        value: ProfileEnum.MANAGER,
      },
      {
        id: 2,
        label: trans('ASSISTANT'),
        value: ProfileEnum.ASSISTANT,
      },
      {
        id: 3,
        label: trans('REPRESENTATIVE'),
        value: ProfileEnum.REPRESENTATIVE,
      },
      {
        id: 4,
        label: trans('CLIENT'),
        value: ProfileEnum.CLIENT,
      },
    ]
  }
}
